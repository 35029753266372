import axios from "axios";

export default {
    async getTecnici() {
        const data = new FormData();
        data.append("where[]", `users_type = '3'`);

        const response = await axios.post("rest/v1/search/users", data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response);
        return response;
    },
};
